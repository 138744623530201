<template>
	<div class="employePerformance">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="display: inline-block;width: 75px;">关键字: </label>
				<el-input v-model="searchKey" placeholder="员工姓名、手机号" style="width: 250px;" clearable></el-input>
			</div>

			<div class="filter-item">
				<label class="label">时间筛选: </label>
				<el-date-picker type="datetime" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='maxOptions'></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='minOptions'></el-date-picker>
			</div>


		</div>

		<div class="filter-container">
			<div class="filter-item">
				<label class="label">门店标签: </label>
				<el-select v-model="ShopTagId" placeholder="请选择" filterable clearable style="width: 250px;">
					<el-option :value="null" label="全部"></el-option>
					<el-option v-for="(item,index) in LabelList" :key="index" :label="item.TagName" :value="item.Id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<label class="label">归属门店: </label>
				<el-select v-model="activeType" clearable filterable style="width: 250px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in doorList" :key="item.Id" :label="item.ShopName" :value="item.Id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<label class="label">在职状态: </label>
				<el-select v-model="incumbency" placeholder="请选择" filterable clearable style="width: 180px;">
					<el-option :value="null" label="全部"></el-option>
					<el-option v-for="(item,index) in incumbencyList" :key="index" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="searchFilter">查询</el-button>
				<el-button @click="exportFun" style="margin-left: 25px;">导出</el-button>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="EmployeeName" label="员工" width="250px;">
					<template slot-scope="scope">
						<div style="display: flex;flex-direction: row;align-items: center;">
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-if="scope.row.WxHeadUrl==''"
							 src='https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png' />
							<!-- <img style="width:50px;height:50px;border-radius:100%;object-fit:contain;" v-if="scope.row.WxHeadUrl==''" src='https://cdn.dkycn.cn/images/melyshop/pcDefaultHeader.png'/> -->
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-else :src='scope.row.WxHeadUrl' />
							<span style="margin-left:5px;">{{scope.row.EmployeeName}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="Phone" label="手机号"></el-table-column>
				<el-table-column prop="ShopName" label="归属门店"></el-table-column>
				<el-table-column label="锁粉人数">
					<template slot-scope="scope">
						<div v-if="scope.row.LockMemberCount>0" style="color: #409EFF;cursor: pointer;" @click="detailsLink(scope.row,1)">{{scope.row.LockMemberCount}}</div>
						<div v-else>{{scope.row.LockMemberCount}}</div>
					</template>
				</el-table-column>
				<el-table-column label="提成">
					<template slot-scope="scope">
						<div v-if="scope.row.AwardMoney>0" style="color: #409EFF;cursor: pointer;" @click="detailsLink(scope.row,3)">{{scope.row.AwardMoney}}</div>
						<div v-else>{{scope.row.AwardMoney}}</div>
					</template>
				</el-table-column>

				<el-table-column label="支付业绩">
					<el-table-column label="销售额">
						<template slot-scope="scope">
							<div v-if="scope.row.OrderPayMoney>0" style="color: #409EFF;cursor: pointer;" @click="detailsLink(scope.row,2,false,2)">{{scope.row.OrderPayMoney}}</div>
							<div v-else>{{scope.row.OrderPayMoney }}</div>
						</template>
					</el-table-column>
					<el-table-column label="退款额">
						<template slot-scope="scope">
							<div v-if="scope.row.RefundMoney>0" style="color: #409EFF;cursor: pointer;" @click="detailsLink(scope.row,2,true,2)">{{scope.row.RefundMoney}}</div>
							<div v-else>{{scope.row.RefundMoney}}</div>
						</template>
					</el-table-column>
				</el-table-column>

				<el-table-column label="结算业绩">
					<el-table-column label="销售额">
						<template slot-scope="scope">
							<div v-if="scope.row.SendOrderMoney>0" style="color: #409EFF;cursor: pointer;" @click="detailsLink(scope.row,2,false,1)">{{scope.row.SendOrderMoney}}</div>
							<div v-else>{{scope.row.SendOrderMoney }}</div>
						</template>
					</el-table-column>
					<el-table-column label="退款额">
						<template slot-scope="scope">
							<div v-if="scope.row.SendOrderRefundMoney >0" style="color: #409EFF;cursor: pointer;" @click="detailsLink(scope.row,2,true,1)">{{scope.row.SendOrderRefundMoney}}</div>
							<div v-else>{{scope.row.SendOrderRefundMoney }}</div>
						</template>
					</el-table-column>
				</el-table-column>

			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>


	</div>

</template>

<script>
	import {
		StoreTagList
	} from '@/api/TurnTomySelf'
	import {
		employeePerformanceList
	} from '@/api/goods'
	import {
		shopdroplist
	} from '@/api/api.js'

	import config from '@/config/index'
	export default {
		data() {
			return {
				searchKey: '',
				starTime: '',
				endTime: '',
				ShopTagId: null,
				LabelList: [],
				activeType: null,
				doorList: [],
				exportUrl: config.EXPORT_URL,
				groupData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				incumbency: 1,
				incumbencyList: [{
						id: 1,
						value: '在职'
					},
					{
						id: 0,
						value: '离职'
					},
					{
						id: 2,
						value: '待分配'
					}
				]

			}
		},
		computed: {
			minOptions: function() {
				let limitTime = this.starTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString())
							)
						}
					}
				}
			}
		},
		beforeMount() {
			this.getStoreLabel()
			this.getList()
		},
		methods: {
			// 获取门店标签
			async getStoreLabel() {
				try {
					let result = await StoreTagList({})
					this.LabelList = result.Result

					let doorResult = await shopdroplist({
						IsOpenRoleJurisdiction: true
					})
					this.doorList = this.doorList.concat(doorResult.Result)
				} catch (err) {

				}
			},
			async getList() {
				this.loading = true
				try {
					let data = {
						KeyWords: this.searchKey,
						StartTime: this.starTime,
						EmployeeState: this.incumbency,
						EndTime: this.endTime,
						ShopTagId: this.ShopTagId,
						ShopId: this.activeType,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await employeePerformanceList(data)
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			// 查询
			searchFilter() {
				this.currentPage = 1
				this.getList();
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			// 导出
			async exportFun() {
				this.loading = true;
				try {
					let url = this.exportUrl + '/pc/employeePerformance/export?' +
						'&KeyWords=' + this.searchKey +
						'&EmployeeState=' + (this.incumbency == null && this.incumbency != 0 ? '' : this.incumbency) +
						'&StartTime=' + this.starTime +
						'&EndTime=' + this.endTime +
						'&ShopTagId=' + (this.ShopTagId ? this.ShopTagId : '') +
						'&ShopId=' + (this.activeType ? this.activeType : '')
					// console.log(url)
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},

			// 跳转至详情页
			detailsLink(record, type, flages,PerformanceType) {
				let pagetype = window.localStorage.getItem('mlmzDistributionModel')==0?'distribution':'agent'
				// console.log(pagetype)
				// 锁粉明细
				if (type == 1) {
					this.$router.push({
						path: `/${pagetype}/door/lockDetailpage`,
						query: {
							Id: record.MallEmployeeId,
							employPhone: record.Phone

						}
					});
				} else if (type == 2) { //销售明细
					this.$router.push({
						path: `/${pagetype}/door/sellDetailpage`,
						query: {
							Id: record.MallEmployeeId,
							employPhone: record.Phone,
							PerformanceType: PerformanceType,
							isRefuse: flages
						}
					});
				} else if (type == 3) { //提成明细
					this.$router.push({
						path: `/${pagetype}/door/drawDetailpage`,
						query: {
							keyId: 2,
							employPhone: record.Phone
						}
					});
				}

			},



		},


	}
</script>

<style lang="less" scoped>
	.employePerformance {
		background: #fff;
		padding: 15px;
	}
</style>
